@import url(https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arvo&family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* :: 1.0 Import Fonts */
/* :: 2.0 Import All CSS */

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Raleway" "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Arvo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* Scroll Bar */

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(66, 66, 66, 0.2);
  border: 0px;
  background-clip: padding-box;
  border-radius: 5px;
}

nav {
  
  padding: 1rem 1rem;
}

#search_btn {
  background-color: #ed5922;
}

.input-group {
  width: 90%;
  margin-right: 35%;
}

#search_field {
  height: 2.4rem;
  padding: 1rem;
}

#login_btn,
#view_btn {
  background-color: #ed5922;
  padding: 0.4rem 1.8rem;
  font-family: calibri;
}

#cart {
  font-size: 1rem;
  color: white;
}

#cart_count {
  background-color: #ed5922;
  padding: 0.15rem 0.4rem;
  border-radius: 0.2rem;
  color: black;
  font-weight: bold;
  font-size: 0.7rem;
}

/* Home page */
#products_heading {
  margin-top: 1.8rem;
}

.product-image {
  max-width: 100%;
  height: auto;
  /* Other styles for the product image */
}

.card {
  height: 100%;
 max-width: 350px;
 box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
 width:100%;
  border-radius: 10px 10px 10px 10px;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.card-title a {
  color: #2e2e2e;
  font-size: 1.15rem;
}

.card-title a:hover {
  color: #ed5922;
  text-decoration: none;
}

.card-body {
  padding-left: 0;
}

.card-text {
  font-size: 1.05rem;
}

@media (max-width: 576px) { /* Adjust the breakpoint as needed */
  .card {
    width:100%;
    border:none; 
    box-shadow: none;
  }
}

#view_btn {
  background-color: #ed5922;
  color: white;
}

.card-img-top {
 display:flex;
 
  width: 250px;
  height: 190px;
  object-fit: fill;
} 
@media (max-width: 576px) { /* Adjust the breakpoint as needed */
  .card-img-top {
    
     width:180px;
     height: 140px;
     object-fit: fill;
   } 
}


.background {
  flex: 1 1 250px;
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Base styles for the product card */
.product-card {
  position: relative;
  /* Other styles for the product card */
}

.ratings {
  font-size: 1.2rem;
  color: #ed5922;
}

#no_of_reviews {
  font-size: 0.85rem;
  color: grey;
  margin-left: 0.5rem;
}

/* Footer */
footer {
  margin-top: 0rem;
  color: grey;
  bottom: 0;
  width: 100%;
  background-color: #51213B;
}

/* Ratings */

.rating-outer {
  display: inline-block;
  position: relative;
  font-family: FontAwesome;
  color: #ed5922;
}

.rating-outer::before {
  content: '\f006 \f006 \f006 \f006 \f006';
}

.rating-inner {
  position: absolute;
  height:100%;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.rating-inner::before {
  content: '\f005 \f005 \f005 \f005 \f005';
  color: #ed5922;
}

/* Product Details Page */
#product_id {
  color: grey;
  font-size: 0.8rem;
}

#product_price {
  font-size: 2rem;
  font-weight: bold;
}

#cart_btn,
#review_btn {
  border-radius: 2rem;
  background-color: #ed5922;
  border: none;
  padding: 0.5rem 2rem;
}

#product_seller {
  color: grey;
  font-size: 0.9rem;
}

#stock_status {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.3rem;
}

.redColor {
  color: red;
}

.greenColor {
  color: green;
}

#product_image {
  margin-top: 9rem;
}

/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}
.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ed5922;
  border-color: #ed5922 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Cart */

.cart-item {
  margin: 1.8rem 0rem;
  padding: 0rem 0.7rem;
}

.cart-item a {
  color: grey;
}

#delete_cart_item {
  color: red;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}

#card_item_price {
  color: #ed5922;
  font-weight: bold;
  font-size: 1.4rem;
}

#checkout_btn,
.review-btn {
  background-color: #ed5922;
  border-color: #ed5922;
  margin-top: 2rem;
  border-radius: 5rem;
}

#view_order_details {
  background-color: #ed5922;
  border-color: #ed5922;
}

#order_summary {
  border: 1px solid #e3e3e3;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
}

.order-summary-values {
  float: right;
  font-weight: bold;
}

.stockCounter input {
  border: none;
  width: 3rem;
  text-align: center;
}

.plus,
.minus {
  padding: 0.1rem 0.5rem;
}

.stockCounter input::-webkit-outer-spin-button,
.stockCounter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
}

/* Login & Register */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;

  font-weight: 700;
}

.wrapper form {
  padding: 2.5rem 3rem;
}

.wrapper form .btn {
  background-color: #ed5922;
  border-color: #ed5922;
  color: white;
  margin-top: 2.5rem;
}

.wrapper form a {
  font-size: 0.9rem;
  color: grey;
}

/* Avatar */
.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

/* Header User Profile */

.avatar-nav {
  margin-right: 0.6rem;
  height: 2.2rem;
  width: 2.2rem;
}

/* Checkout Steps */

.checkout-progress div {
  box-sizing: border-box;
}

.checkout-progress {
  display: block;
  clear: both;
  margin: 20px auto;
  width: auto;
  font-family: sans-serif;
  overflow: auto;
}

.step {
  margin: 0;
  border: 0;
  letter-spacing: 1px;
  line-height: 30px;
  padding: 5px 10px 5px 15px;
  color: grey;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
}

.incomplete {
  background: #eeeeee;
}

.active-step {
  background: #ed5922;
  color: #fff;
}

.triangle-active {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #ed5922;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-active {
  width: 0;
  float: left;
  border-top: 20px solid #ed5922;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #ed5922;
  margin-right: -1px;
}

.triangle-incomplete {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #eeeeee;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-incomplete {
  width: 0;
  float: left;
  border-top: 20px solid #eeeeee;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #eeeeee;
  margin-right: -1px;
}

/* User Profile */

.avatar-profile {
  height: 16rem;
  width: 16rem;
}

.user-info h4 {
  font-weight: 800;
  color: grey;
  margin-top: 2rem;
}

.user-info p {
  font-size: 1.1rem;
}

#edit_profile {
  background-color: #ed5922;
  border-color: #ed5922;
}

/* Confirm Order */

.order-confirm p,
.order-details p {
  margin-left: 1.5rem;
}

.update-btn {
  background-color: #ed5922;
  border-color: #ed5922;
  color: white;
}

.update-btn:hover {
  color: white;
}

/* Pagination */
.page-item.active .page-link {
  background-color: #ed5922;
  border-color: #ed5922;
}

.page-link {
  color: #ed5922;
}

.page-link:hover {
  color: #ed5922;
}

/* Admin Sidebar */

.sidebar-wrapper {
  display: flex;
  min-width: 185px;
  width: 100%;
  align-items: stretch;
  min-height: 100vh;
  font-weight: normal;
  margin-top: 0;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}
#sidebar {
  min-width: 100%;
  max-width: 100%;
  background:radial-gradient(circle, #ED5922, #51213B);
  color: #fcfcfc;
  transition: all 0.3s;
  
}
#sidebar.active {
  margin-left: -200px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #232f3e;
}
#sidebar ul.components {
  padding: 5px 0px;
  border-bottom: 1px solid #232f3e;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: white;
  text-decoration: none;
}
#sidebar ul li a:hover {
  color: #232f3e;
  background: #fff;
}

#sidebar ul li a i {
  margin-right: 0.3rem;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: #fff;
  background: #51213B;
}
a[data-toggle='collapse'] {
  position: relative;
}
.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

ul ul a {
  font-size: 1rem !important;
  padding-left: 30px !important;
  background: #51213B;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

/* Products List Admin */
#products_table .btn {
  padding: 0.2rem 0.5rem;
  margin-left: 0.4rem;
}

/* Dashboard */

.card-font-size {
  font-size: 1.5rem;
}

/* Reviews */

.rating {
  margin-top: 10rem;
}

.stars {
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.star {
  display: inline;
  list-style: none;
  font-size: 3rem;
  padding-left: 0.9rem;
  color: #e3e3e3;
}

.star:first-child {
  padding-left: 0;
}

.orange {
  color: #ed5922;
}

.yellow {
  color: #ed5922;
}

.review_user {
  font-size: 0.8rem;
  color: grey;
}

.menu_header {
  background-color:#ED5922 ;
  color: #ffffff;

}

.my_margin {
  margin-left: 360px;
  margin-right: 360px;
}

.navbartop-custom{
  height:40px;
}
.navbarmid-custom{
  height:120px;
}
.navbarbottom-custom{
  height:50px
}

.footer-custom{
  height:380px;
}


.navbar-light .navbar-toggler-icon {
  color: white;
}

.navbar-light .navbar-nav .nav-link.dropdown-toggle {
  color: white;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #E69637
  ;
}

.dropdown-menu{
  background-color: #ED5922;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #e69637;
}

a[aria-expanded='true'] {
  color: #fff;
  background: #51213B;
}

.nav-link:hover .nav-link:focus{
  color:#E69637
}

.social-media-icons{
  color: #E69637;
  font-size: 1.2rem;
  margin-right: 0.5rem;
}


.dropdown-menu a {
  width: 100%;
  display: block;
}


.strike{
  text-decoration:line-through;
}

.sale-badge {
  position: absolute;
  top: 1px;
  right: -10px;
  background-color: #E69637;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
}

/* Media query for mobile devices (adjust the max-width as per your needs) */
@media (max-width: 767px) {
  .sale-badge {
    font-size: 14px; /* Adjust the font size for mobile */
    padding: 3px 6px; /* Adjust padding for mobile */
    top: 1px; /* Adjust the top position for mobile */
    right: 1px; /* Adjust the right position for mobile */
  }
}

.sale-text {
  font:rubik;
  font-size: 1rem;
  font-weight: bold;
}

.red-row {
  color: red;
}

.clear-colors{
  background-color: #E69637;
  color:#ffffff;
  border:none
}

.table{
  background-color: "red"
}

.artah-text-orange{
  color: #ED5922;
}

.artah-text-yellow{
  color: #E69637;
}

.artah-text-purple{
  color: #51213B;
}


.artah-orange{
 
  background-color: #ED5922;
}

.artah-yellow{
  
  background-color: #E69637;
}

.artah-purple{
  background-color: #51213B;
}

.artah-button{
  color:#ffffff;
  background-color: #ed5922;
}

.marquee-container {
  position: relative;
 
  width: 300px; /* Adjust the width as needed */
  
}

.marquee-content {
  position: relative;
  white-space: nowrap;
  -webkit-animation: marquee 10s linear infinite;
          animation: marquee 10s linear infinite;
 
}

.marquee-text {
  display: inline-block;
   /* Add spacing between texts */
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(40%);
            transform: translateX(40%);
  }
  50% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translateX(40%);
            transform: translateX(40%);
  }
  50% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.error{
  color:red
}



/* Mobile devices */
@media only screen and (max-width: 768px) {
  .img-wrapper {
    position: relative;
    padding-top: 35%; /* Adjust the padding value as per your desired height-to-width ratio */
  }

  .img-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
}

@media only screen and (min-width: 768px) {
  .logo-img {
    max-width: 150px ; /* Adjust the value as needed */
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .sale-adv-img {
    text-align: center;
  }
}

/* animation */

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}


.star-badge {
  width: 100% auto;
  height: 100% auto;
  border-radius: 7px solid/* Set the background color for the star */
  
}

@-webkit-keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.blink {
  -webkit-animation: blink 3s infinite;
          animation: blink 3s infinite;
}

.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}




.circle-icon svg {
  font-size: 2rem; /* Adjust the icon size as needed */
}

@media only screen and (min-width: 768px) {
  .summer-sale-img {
    width: 100%; /* Adjust the value as needed */
    height: 500px;
  }
}


/* Image zoom upon mouse over */

.image-zoom-container {
  overflow: -moz-hidden-unscrollable;
  width: auto; 
  height: auto; 
}

.zoom-image {
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.zoom-image:hover {
  -webkit-transform: scale(2.0);
          transform: scale(2.0); /* Adjust the scale value to control the zoom level */
}

/* Styling for the modal */
.react-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.zoomed-image {
  width: 100%;
  max-width: 500px; /* Adjust the max-width of the zoomed image */
}

/* CSS for the magnifier container */
.magnifier-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* CSS */
.img-border {
  border: 2px solid #ccc; /* Set the border style as per your requirement */
}


.carousel-indicators li {
  display: none;
}

.artah-btn-gradient{
  background:radial-gradient(circle, #ED5922, #ED5922);
}

.card-img {
  padding:1.5 1.5 1.5 1.5;
  object-fit:cover;
  width: 100%;
  height:auto;
  
}

@media only screen and (max-width: 540px) {
.carousel-img{
  height:180px;
}
}   


ul.no-bullets {
  list-style-type: none;
}
